import * as R from 'ramda'

export const capitalizeFirstLetter = str =>
  str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()

export const parsePhoneNumber = (num: any) =>
  R.slice(0, 10, R.replace(/\D/g, '', num))

export const formatPhoneNumber = R.pipe(parsePhoneNumber, n => {
  let str = ''
  if (n.length > 0) str = `(${R.slice(0, 3, n)}`
  if (n.length > 3) str = `${str}) ${R.slice(3, 6, n)}`
  if (n.length > 6) str = `${str}-${R.slice(6, 10, n)}`

  return str
})

export const formatVerbosePhoneNumber = phoneNumberString => {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  var match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`
  }

  return null
}

export const parseJson = json => JSON.parse(json)

export const escapeRegexp = s => {
  return s && s.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')
}

export const formatNumber = R.replace(/\D/g, '')

export const parseCreditCard = R.pipe(R.replace(/[^\d*•]/g, ''), R.slice(0, 19))

export const formatCreditCard = R.pipe(parseCreditCard, n => {
  let formatted = R.slice(0, 4, n)
  let idx = 4

  while (n.length > idx) {
    formatted = formatted.concat(' ', R.slice(idx, idx + 4, n))
    idx += 4
  }

  return formatted
})

export const formatMonthYear = s => {
  const digits = formatNumber(s)

  // skips slash if user is trying to delete so they don't get stuck with "02 /"
  if (R.test(/\/$/, s) || digits.length < 2) {
    return digits
  } else {
    return `${R.slice(0, 2, digits)} / ${R.slice(2, 4, digits)}`
  }
}

export const getDynamicTextSize = (tiers, text) => {
  const sortedTiers = R.sortBy(R.prop('maxLength'), tiers)

  for (const tier of sortedTiers) {
    if (text.length <= tier.maxLength) return tier.size
  }

  return R.last(sortedTiers).size
}

export const capitalize = R.replace(/^./, R.toUpper)

export const removeNonDigits = number => R.replace(/\D/g, '', number || '')

export const parseFloatInput = val => {
  // don't parse if starting to enter decimal
  if (R.last(val) === '.') return val
  const parsedVal = parseFloat(val)
  // only allow valid floats
  if (isNaN(parsedVal)) return ''

  // round to hundredth
  return Math.round(parsedVal * 100) / 100.0
}

export const parseIntInput = val => {
  const parsed = parseInt(R.replace(/\D/g, '', val))

  return isNaN(parsed) ? null : parsed
}

const rejectEmpty = R.reject(R.anyPass([R.isNil, R.isEmpty]))

export const nameList = names =>
  R.join(' and ', rejectEmpty([R.join(', ', R.init(names)), R.last(names)]))

export const cleanPhone = phone => {
  if (!phone) return null

  const digits = phone.replace(/\D/g, '')

  if (digits.length < 10 || digits.length > 11) return null

  const prefix = digits.length === 11 ? `+` : '+1'

  return `${prefix}${digits}`
}

export const isEmptyLike = str => {
  if (R.isNil(str)) return true
  return R.isEmpty(R.trim(str))
}

export const truncate = (str, n = 40) => {
  return str.length > n ? str.substr(0, n - 1) + '…' : str
}

export const slugify = str => {
  if (R.isNil(str)) return str

  return str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '')
}

export function getFirstStringFromOptionalArray(
  value: string | string[] | null
): string | null {
  if (Array.isArray(value)) return value[0]

  return value
}

export function camelize(str) {
  // Step 1: Remove special characters
  const cleanedString = str.replace(/[^a-zA-Z0-9]/g, ' ')

  // Step 2: Convert to camel case
  const words = cleanedString.split(' ')
  const camelCaseString =
    words[0].toLowerCase() +
    words
      .slice(1)
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join('')

  return camelCaseString
}
